import { graphql } from 'relay-runtime'

import { UserIdentitiesQuery } from './__generated__/UserIdentitiesQuery.graphql'

export type { UserIdentitiesQuery }

export const userIdentitiesQuery = graphql`
  query UserIdentitiesQuery(
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $where: UserIdentityWhereInput
    $orderBy: UserIdentityOrder
  ) {
    userIdentities(
      after: $after
      first: $first
      before: $before
      last: $last
      where: $where
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
        ... on UserIdentity {
          id
          createTime
          updateTime
          userID
          accountID
          email
          nationality
          firstName
          lastName
          documentNumber
          dateOfBirth
          documentType
          kycAttemptID
          kycReviewState
          # businessAccountIdentity {
          #   ... on BusinessAccountIdentity {}
          # }
          # contactPersonBusinessAcountIdentity {
          #   ... on BusinessAccountIdentity {}
          # }
          complyCubeClientID
          currentKyc {
            ... on KYCAttempt {
              id
              createTime
              updateTime
              userIdentityID
              state
              countryOfResidence
              userType
              location
              kycTags {
                ... on KYCTag{
                  id
                  value
                }
              }
              kycActionLogs {
                ... on KYCActionLog {
                  id
                  kycAttemptID
                  subjectUserID
                  subjectType
                  targetUserID
                  action
                  prevAttemptState
                  currentAttemptState
                  prevReviewState
                  currentReviewState
                  message
                  data
                }
              }
              extractedInfoID
              extractedInfo {
                ... on KYCExtractedInfo {
                  id
                  createTime
                  updateTime
                  ccCheckID
                  kycAttemptID
                  firstName
                  lastName
                  dateOfBirth
                  nationality
                  documentNumber
                  documentType
                }
              }
            }
          }
          kycAttempts {
            ... on KYCAttempt {
              id
              createTime
              updateTime
              userIdentityID
              state
              countryOfResidence
              userType
              location
              kycTags {
                ... on KYCTag{
                  id
                  value
                }
              }
              extractedInfoID
              extractedInfo {
                ... on KYCExtractedInfo {
                  id
                  createTime
                  updateTime
                  ccCheckID
                  kycAttemptID
                  firstName
                  lastName
                  dateOfBirth
                  nationality
                  documentNumber
                  documentType
                }
              }
            }
          }
        }
      }
      }
    }
  }
`
