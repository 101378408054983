import { Input, Table } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Pagination } from 'src/atoms'
import { TUser, UsersQuery, usersQuery } from 'src/graphql'
import { useBehaviorMapper, useDidMountDebounce } from 'src/hooks'
import { BreadcrumbService, PaginationService } from 'src/services'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'

export const IndividualList: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.MARKET_INDIVIDUAL,
      label: 'Individual'
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const _paginationService = useMemo(() => new PaginationService<TUser>(usersQuery), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.items$).filter(el => el.isBusinessUser !== true)

  const [filter, setFilter] = useState<{ keyword: string }>({ keyword: '' })

  const computedWhere = useMemo<UsersQuery['variables']['where']>(() => {
    const keywordTrimmed = filter.keyword.trim()
    return ({
      or: [
        ...(filter.keyword
          ? [
            { emailContainsFold: keywordTrimmed },
            { firstNameContainsFold: keywordTrimmed },
            { lastNameContainsFold: keywordTrimmed },
            { accountIDContainsFold: keywordTrimmed }
          ]
          : [])
      ],
      isBusinessUser: false
    })
  }, [filter])

  const fresh = useCallback(
    (params?: Parameters<typeof _paginationService.fetch>[0]) => _paginationService.fetch(params),
    [_paginationService]
  )

  useDidMountDebounce(() => {
    fresh({
      after: '',
      before: '',
      where: computedWhere
    })
  }, 500, [computedWhere, fresh])

  useEffect(() => { fresh({ where: { isBusinessUser: false } }) }, [fresh])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend fx-ai-center gap-2">
          <Input
            allowClear
            placeholder="Search by name, account ID & email"
            style={{ flex: '0 0 300px' }}
            readOnly={loading}
            value={filter.keyword}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              keyword: e.target.value
            }))}
          />
        </div>
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        pagination={false}
        loading={loading}
        dataSource={dataSource}
        columns={renderColumns({ onFresh: fresh })}
        scroll={{ x: true }}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        canPrev={_paginationService.canPrev}
        canNext={_paginationService.canNext}
        onPrev={() => _paginationService.prev({ where: computedWhere })}
        onNext={() => _paginationService.next({ where: computedWhere })}
      />
    </section>
  )
}
