import { IRouterOptions } from 'src/router'
import { BusinessList, IndividualKycList, IndividualList } from './components'
import { BusinessKybList } from './components/business-kyb'
import { BusinessKybDetail } from './components/business-kyb/business-kyb-detail'
import { BusinessKycList } from './components/business-kyc'
import { BusinessKycDetail } from './components/business-kyc/business-kyc-detail'
import { BusinessDetail } from './components/business/detail'
import { BusinessUserInfo } from './components/business/user-info'
import { IndividualKycDetail } from './components/individual-kyc/individual-kyc-detail'
import { IndividualDetail } from './components/individual/individual-detail'
import { EPaths } from './routes.path'

export enum ERoutes {
  MARKET_BUSINESS = 'MARKET_BUSINESS',
  MARKET_BUSINESS_DETAIL = 'MARKET_BUSINESS_DETAIL',
  MARKET_BUSINESS_USER_DETAIL = 'MARKET_BUSINESS_USER_DETAIL',
  MARKET_INDIVIDUAL = 'MARKET_INDIVIDUAL',
  MARKET_INDIVIDUAL_DETAIL = 'MARKET_INDIVIDUAL_DETAIL',
  MARKET_INDIVIDUAL_KYC = 'MARKET_INDIVIDUAL_KYC',
  MARKET_INDIVIDUAL_KYC_DETAIL = 'MARKET_INDIVIDUAL_KYC_DETAIL',
  MARKET_BUSINESS_KYC = 'MARKET_BUSINESS_KYC',
  MARKET_BUSINESS_KYC_DETAIL = 'MARKET_BUSINESS_KYC_DETAIL',
  MARKET_BUSINESS_KYB = 'MARKET_BUSINESS_KYB',
  MARKET_BUSINESS_KYB_DETAIL = 'MARKET_BUSINESS_KYB_DETAIL'
}

export const routes: IRouterOptions = [
  {
    path: EPaths.MARKET_BUSINESS,
    name: ERoutes.MARKET_BUSINESS,
    component: BusinessList,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.MARKET_INDIVIDUAL,
    name: ERoutes.MARKET_INDIVIDUAL,
    component: IndividualList,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.MARKET_INDIVIDUAL_DETAIL,
    name: ERoutes.MARKET_INDIVIDUAL_DETAIL,
    component: IndividualDetail,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.MARKET_BUSINESS_DETAIL,
    name: ERoutes.MARKET_BUSINESS_DETAIL,
    component: BusinessDetail,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.MARKET_BUSINESS_USER_DETAIL,
    name: ERoutes.MARKET_BUSINESS_USER_DETAIL,
    component: BusinessUserInfo,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.MARKET_INDIVIDUAL_KYC,
    name: ERoutes.MARKET_INDIVIDUAL_KYC,
    component: IndividualKycList,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.MARKET_INDIVIDUAL_KYC_DETAIL,
    name: ERoutes.MARKET_INDIVIDUAL_KYC_DETAIL,
    component: IndividualKycDetail,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.MARKET_BUSINESS_KYC,
    name: ERoutes.MARKET_BUSINESS_KYC,
    component: BusinessKycList,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.MARKET_BUSINESS_KYC_DETAIL,
    name: ERoutes.MARKET_BUSINESS_KYC_DETAIL,
    component: BusinessKycDetail,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.MARKET_BUSINESS_KYB,
    name: ERoutes.MARKET_BUSINESS_KYB,
    component: BusinessKybList,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.MARKET_BUSINESS_KYB_DETAIL,
    name: ERoutes.MARKET_BUSINESS_KYB_DETAIL,
    component: BusinessKybDetail,
    meta: {
      requiredAuth: true
    }
  }
]

export const MarketModule = {
  ERoutes,
  routes
}
