import { Table } from 'antd'
import { FC, useMemo } from 'react'
import { TBusinessUserPii, TUserPii } from 'src/graphql'
import { renderColumns } from 'src/modules/market/components/individual-kyc/columns'

interface IProps {
  user?: TBusinessUserPii | null
}

export const BusinessKybUserList: FC<IProps> = ({ user }) => {
  const dataSource = useMemo(() => {
    return user?.userIdentities || [] as TUserPii[]
  }, [user?.userIdentities])

  return (
    <section className="fx fx-column fx-extend">
      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        pagination={false}
        dataSource={dataSource}
        columns={renderColumns({ accountType: 'business' })}
        scroll={{ x: true }}
      />
    </section>
  )
}
