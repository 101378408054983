import { graphql } from 'relay-runtime'

import { UserIdentityQuery } from './__generated__/UserIdentityQuery.graphql'

export type { UserIdentityQuery }

export enum EReferralLevel {
  AGENT = 'agent',
  AFFILIATE = 'affiliate',
  SUB_AFFILIATE = 'sub_affiliate',
  ADVOCATE = 'advocate',
  USER = 'user'
}

export const EReferralLevelTxt: Record<string, string> = {
  [EReferralLevel.AGENT]: 'Agent',
  [EReferralLevel.AFFILIATE]: 'Affiliate',
  [EReferralLevel.SUB_AFFILIATE]: 'Sub Affiliate',
  [EReferralLevel.ADVOCATE]: 'Advocate',
  [EReferralLevel.USER]: 'User'
}

export type TUserIdentity = Partial<DeepExclude<DeepWriteable<UserIdentityQuery>, null>['response']['userIdentity']['userIdentity']>

export const userIdentityQuery = graphql`
  query UserIdentityQuery($id: ID!) {
    userIdentity(id: $id) {
      userIdentity {
        lastName
        firstName
        fullAddress
        dateOfBirth
        nationality
        countryOfBirth
        countryOfResidence
      }
    }
  }
`
