import {
  AddKYCAttemptCommentMutation,
  AdminUpdateBusinessProfileMutation,
  AdminUpdatePersonalAddressMutation,
  AdminUpdateUserIdentityMutation,
  AdminUploadBusinessDocumentMutation,
  BusinessAccountIdentitiesQuery,
  BusinessAccountIdentityQuery,
  CreateKYCReviewDocumentMutation,
  EditRiskScoreMutation,
  GenerateKYBDocumentsSignedURLMutation,
  GetUserIdentityQuery,
  KycReviewDocumentsQuery,
  KycReviewDocumentsSignedURLQuery,
  QuestionSetQuery,
  RecalculateRiskScoreMutation,
  ReviewKYBAttemptMutation,
  ReviewKYCAttemptMutation,
  SupportedCountriesQuery,
  UpdatePersonalInformationMutation,
  UserIdentitiesQuery,
  addKYCAttemptCommentMutation,
  adminUpdatePersonalAddressMutation,
  adminUpdateUserIdentityMutation,
  adminUploadBusinessDocumentMutation,
  businessAccountIdentitiesQuery,
  businessAccountIdentityQuery,
  createKYCReviewDocumentMutation,
  editRiskScoreMutation,
  generateKYBDocumentsSignedURLMutation,
  getUserIdentityQuery,
  kycReviewDocumentsQuery,
  kycReviewDocumentsSignedURLQuery,
  questionSetQuery,
  recalculateRiskScoreMutation,
  reviewKYBAttemptMutation,
  reviewKYCAttemptMutation,
  supportedCountriesQuery,
  updatePersonalInformationMutation
} from 'src/graphql'
import { PiiGraphqlService } from 'src/services'

export class PiiApi {
  /**
   * SECTION: Queries
   */

  static kycReviewDocumentsSignedURL(variables: KycReviewDocumentsSignedURLQuery['variables']) {
    return PiiGraphqlService.queryAsPromise<KycReviewDocumentsSignedURLQuery>(
      kycReviewDocumentsSignedURLQuery,
      variables
    ).then((response) => response.kycReviewDocumentsSignedURL)
  }

  static userIdentities(variables: UserIdentitiesQuery['variables']) {
    return PiiGraphqlService.queryAsPromise<UserIdentitiesQuery>(
      getUserIdentityQuery,
      variables
    ).then((response) => response.userIdentities)
  }

  static getUserIdentity(variables: GetUserIdentityQuery['variables']) {
    return PiiGraphqlService.queryAsPromise<GetUserIdentityQuery>(
      getUserIdentityQuery,
      variables
    ).then((response) => response.getUserIdentity.data)
  }

  static questionSet(variables: QuestionSetQuery['variables']) {
    return PiiGraphqlService.queryAsPromise<QuestionSetQuery>(
      questionSetQuery,
      variables
    ).then((response) => response.questionSet)
  }

  static supportedCountries(variables: SupportedCountriesQuery['variables']) {
    return PiiGraphqlService.queryAsPromise<SupportedCountriesQuery>(
      supportedCountriesQuery,
      variables
    ).then((response) => response.supportedCountries.countries)
  }

  static kycReviewDocuments(variables: KycReviewDocumentsQuery['variables']) {
    return PiiGraphqlService.queryAsPromise<KycReviewDocumentsQuery>(
      kycReviewDocumentsQuery,
      variables
    ).then((response) => response.kycReviewDocuments?.documents || [])
  }

  static businessAccountIdentity(variables: BusinessAccountIdentityQuery['variables']) {
    return PiiGraphqlService.queryAsPromise<BusinessAccountIdentityQuery>(
      businessAccountIdentityQuery,
      variables
    ).then((response) => response.businessAccountIdentity.data)
  }

  static businessAccountIdentities(variables: BusinessAccountIdentitiesQuery['variables']) {
    return PiiGraphqlService.queryAsPromise<BusinessAccountIdentitiesQuery>(
      businessAccountIdentitiesQuery,
      variables
    ).then((response) => response.businessAccountIdentities)
  }

  /**
   * SECTION: Mutations
   */

  static adminUpdateUserIdentity(
    variables: AdminUpdateUserIdentityMutation['variables']
  ) {
    return PiiGraphqlService.mutationAsPromise<AdminUpdateUserIdentityMutation>(
      adminUpdateUserIdentityMutation,
      variables
    ).then((response) => response.adminUpdateUserIdentity)
  }

  static adminUpdatePersonalAddress(
    variables: AdminUpdatePersonalAddressMutation['variables']
  ) {
    return PiiGraphqlService.mutationAsPromise<AdminUpdatePersonalAddressMutation>(
      adminUpdatePersonalAddressMutation,
      variables
    ).then((response) => response.adminUpdatePersonalAddress)
  }

  static createKYCReviewDocument(
    variables: CreateKYCReviewDocumentMutation['variables']
  ) {
    return PiiGraphqlService.mutationAsPromise<CreateKYCReviewDocumentMutation>(
      createKYCReviewDocumentMutation,
      variables
    ).then((response) => response.createKYCReviewDocument)
  }

  static addKYCAttemptComment(
    variables: AddKYCAttemptCommentMutation['variables']
  ) {
    return PiiGraphqlService.mutationAsPromise<AddKYCAttemptCommentMutation>(
      addKYCAttemptCommentMutation,
      variables
    ).then((response) => response.addKYCAttemptComment)
  }

  static reviewKYCAttempt(
    variables: ReviewKYCAttemptMutation['variables']
  ) {
    return PiiGraphqlService.mutationAsPromise<ReviewKYCAttemptMutation>(
      reviewKYCAttemptMutation,
      variables
    ).then((response) => response.reviewKYCAttempt)
  }

  static updatePersonalInformation(
    variables: UpdatePersonalInformationMutation['variables']
  ) {
    return PiiGraphqlService.mutationAsPromise<UpdatePersonalInformationMutation>(
      updatePersonalInformationMutation,
      variables
    ).then((response) => response.updatePersonalInformation.data)
  }

  static editRiskScore(
    variables: EditRiskScoreMutation['variables']
  ) {
    return PiiGraphqlService.mutationAsPromise<EditRiskScoreMutation>(
      editRiskScoreMutation,
      variables
    ).then((response) => response.editRiskScore.data)
  }

  static adminUpdateBusinessProfile(
    variables: AdminUpdateBusinessProfileMutation['variables']
  ) {
    return PiiGraphqlService.mutationAsPromise<AdminUpdateBusinessProfileMutation>(
      adminUpdateUserIdentityMutation,
      variables
    ).then((response) => response.adminUpdateBusinessProfile.data)
  }

  static reviewKYBAttempt(
    variables: ReviewKYBAttemptMutation['variables']
  ) {
    return PiiGraphqlService.mutationAsPromise<ReviewKYBAttemptMutation>(
      reviewKYBAttemptMutation,
      variables
    ).then((response) => response.reviewKYBAttempt.data)
  }

  static adminUploadBusinessDocument(
    variables: AdminUploadBusinessDocumentMutation['variables']
  ) {
    return PiiGraphqlService.mutationAsPromise<AdminUploadBusinessDocumentMutation>(
      adminUploadBusinessDocumentMutation,
      variables
    ).then((response) => response.adminUploadBusinessDocument.data)
  }

  static generateKYBDocumentsSignedURL(
    variables: GenerateKYBDocumentsSignedURLMutation['variables']
  ) {
    return PiiGraphqlService.mutationAsPromise<GenerateKYBDocumentsSignedURLMutation>(
      generateKYBDocumentsSignedURLMutation,
      variables
    ).then((response) => response.generateKYBDocumentsSignedURL)
  }

  static recalculateRiskScore(
    variables: RecalculateRiskScoreMutation['variables']
  ) {
    return PiiGraphqlService.mutationAsPromise<RecalculateRiskScoreMutation>(
      recalculateRiskScoreMutation,
      variables
    ).then((response) => response.recalculateRiskScore.data)
  }
}
