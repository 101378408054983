import { graphql } from 'relay-runtime'

import { SupportedCountriesQuery } from './__generated__/SupportedCountriesQuery.graphql'

export type { SupportedCountriesQuery }

export type TCountry = DeepWriteable<TArrayItem<SupportedCountriesQuery['response']['supportedCountries']['countries']>>

export const supportedCountriesQuery = graphql`
  query SupportedCountriesQuery {
    supportedCountries {
      countries {
        id
        code
        name
      }
    }
  }
`
