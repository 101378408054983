export enum EPaths {
  MARKET_BUSINESS = '/market/business',
  MARKET_BUSINESS_DETAIL = '/market/business/:id',
  MARKET_BUSINESS_USER_DETAIL = '/market/business/:businessId/:userId',
  MARKET_INDIVIDUAL = '/market/individual',
  MARKET_INDIVIDUAL_DETAIL = '/market/individual/:id',
  MARKET_INDIVIDUAL_KYC = '/market/individual-kyc',
  MARKET_INDIVIDUAL_KYC_DETAIL = '/market/individual-kyc/:accountID/:userID',
  MARKET_BUSINESS_KYC = '/market/business-kyc',
  MARKET_BUSINESS_KYC_DETAIL = '/market/business-kyc/:accountID/:userID',
  MARKET_BUSINESS_KYB = '/market/business-kyb',
  MARKET_BUSINESS_KYB_DETAIL = '/market/business-kyb/:accountID'
}
