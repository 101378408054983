import { Tag } from 'antd'
import { TUserPii } from 'src/graphql'

export class KycUtils {
  static renderTags(user?: TUserPii | null) {
    return user?.currentKyc?.kycTags?.map((tag) => (
      <Tag
        key={tag.id}
        color={({
          HIGH_RISK: 'red',
          LOW_RISK: 'green',
          MEDIUM_RISK: 'yellow'
        })[String(tag.value)]}
      >
        {tag.value}
      </Tag>
    ))
  }

  static canStartReview(user?: TUserPii | null) {
    return (
      user?.currentKyc?.state === 'MANUAL_APPROVE_PENDING' ||
      user?.kycReviewState === 'APPLICATION_ONGOING'
    )
  }

  static canOnHold(user?: TUserPii | null) {
    return true
  }

  static canApprove(user?: TUserPii | null) {
    return true
  }

  static canDeny(user?: TUserPii | null) {
    return true
  }
}
