import { Input, Select, Table } from 'antd'
import qs from 'qs'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Pagination } from 'src/atoms'
import { BusinessAccountIdentitiesQuery, TBusinessUserPii, businessAccountIdentitiesQuery } from 'src/graphql'
import { useBehaviorMapper, useDidMountDebounce } from 'src/hooks'
import { EKybStatus } from 'src/interfaces'
import { BreadcrumbService, PaginationService, SearchParamKey, SearchParamsService } from 'src/services'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'

const ONE_DAY = 24 * 60 * 60 * 1000

export const BusinessKybList: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.MARKET_BUSINESS_KYB,
      label: 'Business KYB'
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const _paginationService = useMemo(() => new PaginationService<TBusinessUserPii>(businessAccountIdentitiesQuery, { usePii: true }), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.items$)

  const updateTimeOptions = useMemo(() => [
    <Select.Option key={1} value={new Date(Date.now() - ONE_DAY).toISOString()}>
      Last 24 hours
    </Select.Option>,
    <Select.Option key={2} value={new Date(Date.now() - 7 * ONE_DAY).toISOString()}>
      Last 7 days
    </Select.Option>,
    <Select.Option key={3} value={new Date(Date.now() - 30 * ONE_DAY).toISOString()}>
      Last 1 month
    </Select.Option>
  ], [])

  const kycReviewStateOptions = useMemo(() => {
    return Object.values(EKybStatus).map((value) => (
      <Select.Option key={value} value={value}>
        {value}
      </Select.Option>
    ))
  }, [])

  const [filter, setFilter] = useState<{
    kybStatus?: Array<Exclude<Exclude<BusinessAccountIdentitiesQuery['variables']['where'], null | undefined>['finalState'], null | undefined>>
    updateTimeGTE?: string
    keyword?: string
  }>({
    ...qs.parse(SearchParamsService.getSearchParams(SearchParamKey.BUSINESS_KYB), { ignoreQueryPrefix: true })
  })

  const computedWhere = useMemo<BusinessAccountIdentitiesQuery['variables']['where']>(() => {
    const keywordTrimmed = filter?.keyword?.trim()
    return ({
      and: [{
        updateTimeGTE: filter.updateTimeGTE || undefined,
        finalStateIn: filter.kybStatus?.length ? filter.kybStatus : undefined
      },
      {
        or: keywordTrimmed
          ? [
            { emailContainsFold: keywordTrimmed },
            { fullLegalNameContainsFold: keywordTrimmed },
            { doingBusinessAsNameContainsFold: keywordTrimmed },
            { accountIDContainsFold: keywordTrimmed }
          ]
          : undefined
      }
      ].filter((conditions) => Object.values(conditions).filter(Boolean).length > 0)
    })
  }, [filter])

  const fresh = useCallback(
    (params?: BusinessAccountIdentitiesQuery['variables']) => _paginationService.fetch(params as any),
    [_paginationService]
  )

  useDidMountDebounce(() => {
    fresh({
      after: '',
      before: '',
      where: computedWhere
    })
  }, 500, [computedWhere, fresh])

  useEffect(() => {
    fresh({
      after: '',
      before: '',
      where: computedWhere
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onGoDetail = useCallback(() => {
    SearchParamsService.setSearchParams(SearchParamKey.BUSINESS_KYB, qs.stringify(filter, { arrayFormat: 'brackets', skipNulls: true }))
  }, [filter])
  useEffect(() => {
    SearchParamsService.setSearchParams(SearchParamKey.BUSINESS_KYB, '')
  }, [])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend fx-ai-center gap-2">
          <Input
            allowClear
            placeholder="Search by name, account ID & email"
            style={{ flex: '0 0 300px' }}
            readOnly={loading}
            value={filter.keyword}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              keyword: e.target.value
            }))}
          />

          <Select
            allowClear
            style={{ flex: '0 0 200px' }}
            placeholder="Select last update time range"
            value={filter.updateTimeGTE}
            onChange={(updateTimeGTE) => setFilter((prev) => ({
              ...prev,
              updateTimeGTE
            }))}
            maxTagCount="responsive"
          >
            {updateTimeOptions}
          </Select>

          <Select
            allowClear
            mode="multiple"
            style={{ flex: '0 0 200px' }}
            placeholder="KYB status"
            value={filter.kybStatus}
            onChange={(kybStatus) => setFilter((prev) => ({
              ...prev,
              kybStatus
            }))}
            maxTagCount="responsive"
          >
            {kycReviewStateOptions}
          </Select>
        </div>
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        pagination={false}
        loading={loading}
        dataSource={dataSource}
        columns={renderColumns({ onGoDetail })}
        scroll={{ x: true }}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        canPrev={_paginationService.canPrev}
        canNext={_paginationService.canNext}
        onPrev={() => _paginationService.prev({ where: computedWhere })}
        onNext={() => _paginationService.next({ where: computedWhere })}
      />
    </section>
  )
}
