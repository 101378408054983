import { graphql } from 'relay-runtime'

import { BusinessAccountIdentityQuery } from './__generated__/BusinessAccountIdentityQuery.graphql'

export type { BusinessAccountIdentityQuery }

export type TBusinessUserPii = Partial<DeepWriteable<BusinessAccountIdentityQuery['response']['businessAccountIdentity']['data']>>

export const businessAccountIdentityQuery = graphql`
  query BusinessAccountIdentityQuery($accountID: String!) {
    businessAccountIdentity(accountID: $accountID) {
      data {
        ... on BusinessAccountIdentity {
          id
          createTime
          updateTime
          accountID
          fullLegalName
          doingBusinessAsName
          businessType
          incorporationCountry
          dateOfIncorporation
          incorporationNumber
          website
          email
          phone
          legalAddress
          primaryAddress
          finalState
          currentKybAttemptID
          currentKyb {
            ... on KYBAttempt {
              id
              createTime
              updateTime
              businessAccountIdentityID
              state
              documents {
                ...on KYBDocument {
                  id
                  createTime
                  updateTime
                  kybAttemptID
                  uploaderUserID
                  uploaderRole
                  url
                  type
                  fileName
                  comment
                }
              }
              actionLogs {
                ... on KYBActionLog {
                  id
                  createTime
                  updateTime
                  kybAttemptID
                  subjectUserID
                  subjectType
                  subjectEmail
                  prevAttemptState
                  currentAttemptState
                  action
                  message
                  data
                }
              }
            }
          }
          userIdentities {
            ... on UserIdentity {
              id
              userID
              accountID
              email
              nationality
              firstName
              lastName
              canRestartKYC
              dateOfBirth
              documentNumber
              documentType
              kycAttemptID
              kycReviewState
              # businessAccountIdentity {
              #   ... on BusinessAccountIdentity {}
              # }
              # contactPersonBusinessAcountIdentity {
              #   ... on BusinessAccountIdentity {}
              # }
              complyCubeClientID
              currentKyc {
                ... on KYCAttempt {
                  id
                  createTime
                  updateTime
                  userIdentityID
                  state
                  countryOfResidence
                  userType
                  location
                  riskScore {
                    ... on KYCRiskScore {
                      id
                      score
                      level
                      version
                    }
                  }
                  address {
                    ... on Address {
                      id
                      createTime
                      updateTime
                      ccAddressID
                      addressLineOne
                      addressLineTwo
                      city
                      postalCode
                      stateProvince
                    }
                  }
                  questionAnswers {
                    ... on QuestionAnswer {
                      id
                      createTime
                      updateTime
                      questionID
                      kycAttemptID
                      optionID
                      option {
                        ... on QuestionAnswerOption {
                          id
                          display
                        }
                      }
                      question {
                        ... on Question {
                          id
                          display
                          type
                        }
                      }
                    }
                  }
                  kycTags {
                    ... on KYCTag{
                      id
                      value
                    }
                  }
                  kycReviewDocuments {
                    ... on KYCReviewDocument {
                      id
                      createTime
                      updateTime
                      uploaderUserID
                      url
                      type
                      comment
                    }
                  }
                  kycActionLogs {
                    ... on KYCActionLog {
                      id
                      createTime
                      updateTime
                      kycAttemptID
                      subjectUserID
                      subjectEmail
                      subjectType
                      targetUserID
                      action
                      prevAttemptState
                      currentAttemptState
                      prevReviewState
                      currentReviewState
                      message
                      data
                    }
                  }
                  complyCubeChecks {
                    ... on ComplyCubeCheck {
                      id
                      ccCheckID
                      ccType
                      ccDocumentID
                      ccLivePhotoID
                      ccStatus
                      ccOutcome
                    }
                  }
                  extractedInfoID
                  extractedInfo {
                    ... on KYCExtractedInfo {
                      id
                      createTime
                      updateTime
                      ccCheckID
                      kycAttemptID
                      firstName
                      lastName
                      dateOfBirth
                      nationality
                      documentNumber
                      documentType
                      expirationDate
                      issueDate
                      issueCountry
                      issueAuthority
                    }
                  }
                }
              }
              kycAttempts {
                ... on KYCAttempt {
                  id
                  createTime
                  updateTime
                  userIdentityID
                  state
                  countryOfResidence
                  userType
                  location
                  kycTags {
                    ... on KYCTag{
                      id
                      value
                    }
                  }
                  extractedInfoID
                  extractedInfo {
                    ... on KYCExtractedInfo {
                      id
                      createTime
                      updateTime
                      ccCheckID
                      kycAttemptID
                      firstName
                      lastName
                      dateOfBirth
                      nationality
                      documentNumber
                      documentType
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
