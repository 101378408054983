import { Button, Form, Input, Modal, Select } from 'antd'
import { ComponentProps, FC, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AccountApi } from 'src/api'
import { EAccountState, EAccountStateUpdateReason } from 'src/constants'
import { TUser } from 'src/graphql'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { MessageService } from 'src/services'

type FieldType = {
  state: EAccountState
  reason: EAccountStateUpdateReason
  comment: string
}

export const DialogChangeAccountState: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  user?: TUser | null
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
}> = ({
  user,
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState(true)

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const afterCloseRef = useAsRef(props.afterClose)
  const currentState = user?.account.state

  const [form] = Form.useForm()
  useEffect(() => {
    if (!invisible) {
      form.setFieldsValue({ state: currentState })
    }
  }, [invisible, currentState, form])

  const submit = useCallback(async (values: FieldType) => {
    if (!user?.accountID) {
      return MessageService.error('Something went wrong')
    }
    const input = {
      accountID: user?.accountID,
      state: values.state,
      reason: values.reason,
      comment: values.comment
    }
    setLoading(true)
    from(AccountApi.updateAccountState({ input }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('Change account state successfully')
        setInvisible(true)
        form.resetFields()
        afterCloseRef.current?.(true)
      })
  }, [afterCloseRef, form, unsubscribe$, user?.accountID])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Change account state'}
      </Button>

      <Modal
        title={props.title || 'Change account state'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={() => form.submit()}
        onCancel={() => {
          setInvisible(true)
          form.resetFields()
        }}
      >
        {!invisible && (
          <Form
            form={form}
            name="change-account-state"
            labelCol={{ span: 6 }}
            style={{ maxWidth: 600 }}
            onFinish={submit}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label="Account state"
              name="state"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                options={Object.values(EAccountState).map((reason) => ({
                  label: reason,
                  value: reason
                }))}
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="Reason"
              name="reason"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                options={Object.values(EAccountStateUpdateReason).map((reason) => ({
                  label: reason,
                  value: reason
                }))}
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="Comment"
              name="comment"
              rules={[{ required: true }]}
            >
              <Input.TextArea/>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )
}
