import { ReloadOutlined } from '@ant-design/icons'
import { Button, Descriptions, DescriptionsProps, Space } from 'antd'
import { FC, useMemo } from 'react'
import { TUserPii } from 'src/graphql'
import { DialogChangeKycRiskLevel } from 'src/partials'
import { CountriesStore } from 'src/store'
import { KycUtils } from 'src/utils'

interface IProps {
  user?: TUserPii | null
  loading?: boolean
  onUpdated?: () => void
  onRecalculateRiskScore?: () => void
}

export const ApplicationProgress: FC<IProps> = ({ user, loading, onUpdated, onRecalculateRiskScore }) => {
  const items: DescriptionsProps['items'] = useMemo(
    () => [
      {
        label: 'Status',
        children: user?.kycReviewState
      },
      {
        label: 'Can restart?',
        children: user?.canRestartKYC ? 'Yes' : 'No'
      },
      {
        label: 'Status details',
        children: user?.currentKyc?.state || 'N/A'
      },
      {
        label: (
          <div className="fx fx-ai-center fx-jc-space-between">
            Risk score

            {!!user?.currentKyc?.id && (
              <Button size="small" onClick={onRecalculateRiskScore} disabled={loading}>
                <ReloadOutlined/>
                Refresh
              </Button>
            )}
          </div>
        ),
        children: user?.currentKyc?.riskScore?.score || 'N/A'
      },
      {
        label: (
          <div className="fx fx-ai-center fx-jc-space-between">
            Tags
            {user?.currentKyc?.riskScore && (
              <DialogChangeKycRiskLevel
                user={user}
                btnProps={{
                  type: 'link',
                  style: { margin: '-8px 0' }
                }}
                afterClose={(updated) => updated && onUpdated?.()}
              />
            )}
          </div>
        ),
        children: (
          <Space size={[0, 8]} wrap>
            {KycUtils.renderTags(user)}
          </Space>
        )
      },
      {
        label: 'IP location',
        children: CountriesStore.getCountryName(user?.currentKyc?.location) || '--'
      }
    ].map((el, index) => ({
      ...el,
      key: index,
      style: { width: '50%' }
    })),
    [user, loading, onUpdated, onRecalculateRiskScore]
  )

  return (
    <section>
      <Descriptions
        layout="vertical"
        size="small"
        column={2}
        bordered
        items={items}
      />
    </section>
  )
}
