import { Button, Space } from 'antd'
import { Link } from 'react-router-dom'
import { EReferralLevelTxt, TUser } from 'src/graphql'
import { DialogAddReferringAccount, DialogChangeAccountState, DialogFeeLevel, DialogReferralLevel, DialogReferringAccount } from 'src/partials'
import { generate } from 'src/router'
import { EPaths } from '../../routes.path'

interface Actions {
  onFresh?: () => void
}

export const renderColumns = ({ onFresh }: Actions) => [
  {
    title: 'User name',
    dataIndex: 'username',
    key: 'username',
    render: (_: any, record: TUser) => [record.firstName, record.lastName].filter(Boolean).join(' ')
  },
  {
    title: 'User ID',
    dataIndex: 'id',
    key: 'id',
    className: 'break-all min-w-200'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Fee LV',
    dataIndex: 'feeLevel',
    key: 'feeLevel',
    render: (_: any, record: TUser) => (
      <DialogFeeLevel
        accountID={record.accountID}
        value={record.account?.feeLevel}
        btnProps={{ size: 'small', type: 'link' }}
        afterClose={(fresh) => fresh && onFresh?.()}
      >
        {record.account?.feeLevel || '--'}
      </DialogFeeLevel>
    )
  },
  {
    title: 'Referral level',
    dataIndex: 'referralLevel',
    key: 'referralLevel',
    render: (_: any, record: TUser) => (
      <DialogReferralLevel
        accountID={record.accountID}
        value={record.account.referralTier}
        btnProps={{ size: 'small', type: 'link' }}
        afterClose={(fresh) => fresh && onFresh?.()}
      >
        {EReferralLevelTxt[record.account.referralTier]}
      </DialogReferralLevel>
    )
    /* TODO: wait api supported */
    // filters: Object.values(EReferralLevel).map(el => ({
    //   value: el,
    //   text: EReferralLevelTxt[el]
    // }))
  },
  {
    title: 'Referral code',
    dataIndex: 'referralCode',
    key: 'referralCode',
    render: (_: any, record: TUser) => record.account.referralCode || '--'
  },
  {
    title: 'Referring code',
    dataIndex: 'referringCode',
    key: 'referringCode',
    render: (_: any, record: TUser) => {
      const referralCode = record.account.referringAccount?.referralCode

      return (
        <>
          {!!referralCode && (
            <DialogReferringAccount
              btnProps={{ size: 'small', type: 'link' }}
              referringAccount={record.account.referringAccount}
            >
              {referralCode}
            </DialogReferringAccount>
          )}
          <DialogAddReferringAccount
            accountID={record.accountID}
            btnProps={{ size: 'small', type: 'link' }}
            afterClose={(fresh) => fresh && onFresh?.()}
            referringCode={referralCode}
          >
            {referralCode ? 'Edit' : 'Add'}
          </DialogAddReferringAccount>
        </>
      )
    }
  },
  {
    title: 'Account state',
    dataIndex: 'state',
    key: 'accountState',
    render: (_: any, record: TUser) => (
      <DialogChangeAccountState
        user={record}
        btnProps={{ size: 'small', type: 'link' }}
        afterClose={(fresh) => fresh && onFresh?.()}
      >
        {record.account.state}
      </DialogChangeAccountState>
    )
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render: (_: any, record: TUser) => (
      <Space>
        <Button
          size="small"
          type="link"
        >
          <Link to={generate([EPaths.MARKET_INDIVIDUAL_DETAIL, { id: record.id }])}>
            Detail
          </Link>
        </Button>
      </Space>
    )
  }
]
