import { graphql } from 'relay-runtime'

import { BusinessAccountIdentitiesQuery } from './__generated__/BusinessAccountIdentitiesQuery.graphql'

export type { BusinessAccountIdentitiesQuery }

export const businessAccountIdentitiesQuery = graphql`
  query BusinessAccountIdentitiesQuery(
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $where: BusinessAccountIdentityWhereInput
  ) {
    businessAccountIdentities(
      after: $after
      first: $first
      before: $before
      last: $last
      where: $where
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
        ... on BusinessAccountIdentity {
          id
          createTime
          updateTime
          accountID
          fullLegalName
          doingBusinessAsName
          businessType
          incorporationCountry
          dateOfIncorporation
          incorporationNumber
          website
          email
          phone
          legalAddress
          primaryAddress
          finalState
          currentKybAttemptID
          currentKyb {
            ... on KYBAttempt {
              id
              createTime
              updateTime
              businessAccountIdentityID
              state
              documents {
                ...on KYBDocument {
                  id
                  url
                  type
                }
              }
            }
          }
        }
      }
      }
    }
  }
`
